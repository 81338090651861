import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { AddProgramRequestBodyI } from './interfaces/add-program-request-body.interface';
import { EditProgramRequestBodyI } from './interfaces/edit-program-request-body.interface';
import { FilterProgramsI } from './interfaces/filter-programs.interface';
import { ProgramI } from './interfaces/program.interface';
import { DeleteProgramRequestQuery } from './interfaces/delete-program-request-query.interface';
import { PloI } from '../../shared/interfaces/plo.interface';
import { AddPloRequestBodyI } from '../../shared/interfaces/add-plo-request-body.interface';
import { DeletePloRequestQuery } from '../../shared/interfaces/delete-plo-request-query.interface';
import { EditPloRequestBodyI } from '../../shared/interfaces/edit-plo-request-body.interface';
import { EditEvaluationCriterionRequestBodyI } from './interfaces/edit-evaluation-criterion-request-body.interface';
import { AcademicYearI } from '../academic-years/academic-year/interfaces/academic-year.interface';
import { AolReportRequestQueryI } from './interfaces/aol-report-request-query.interface';
import { AolProgramI } from './interfaces/aol-program.interface';

@Injectable({
  providedIn: 'root',
})
export class ProgramsApiService {
  constructor(private readonly apiService: ApiService) {}

  findAllForFaculty(facultyID: string, filterPrograms: FilterProgramsI) {
    return this.apiService.get<ProgramI[]>(
      `programs/faculty/${facultyID}`,
      filterPrograms
    );
  }

  findAllForDepartment(departmentID: string, filterPrograms: FilterProgramsI) {
    return this.apiService.get<ProgramI[]>(
      `programs/department/${departmentID}`,
      filterPrograms
    );
  }

  findAll(filterPrograms: FilterProgramsI) {
    return this.apiService.get<ProgramI[]>('programs', filterPrograms);
  }

  findOne(programID: string) {
    return this.apiService.get<ProgramI>(`programs/${programID}`);
  }

  findOneAol(programID: string) {
    return this.apiService.get<{
      program: ProgramI;
      academicYears: AcademicYearI[];
    }>(`programs/aol/${programID}`);
  }

  addProgram(
    addProgramRequestBody: AddProgramRequestBodyI,
    departmentID: string
  ) {
    return this.apiService.post<ProgramI>(
      `programs/${departmentID}`,
      addProgramRequestBody
    );
  }

  editProgram(
    programID: string,
    editProgramRequestBody: EditProgramRequestBodyI
  ) {
    return this.apiService.patch<ProgramI>(
      `programs/${programID}`,
      editProgramRequestBody
    );
  }

  deleteProgram(
    programID: string,
    deleteProgramRequestQuery: DeleteProgramRequestQuery
  ) {
    return this.apiService.delete<ProgramI>(
      `programs/${programID}`,
      deleteProgramRequestQuery
    );
  }

  addPlo(addPloRequestBody: AddPloRequestBodyI, programID: string) {
    return this.apiService.post<PloI>(`plos/${programID}`, addPloRequestBody);
  }

  editPlo(ploID: string, editPloRequestBody: EditPloRequestBodyI) {
    return this.apiService.patch<PloI>(`plos/${ploID}`, editPloRequestBody);
  }

  deletePlo(ploID: string, deletePloRequestQuery: DeletePloRequestQuery) {
    return this.apiService.delete<PloI>(`plos/${ploID}`, deletePloRequestQuery);
  }

  findAllPlo(programID: string) {
    return this.apiService.get<PloI[]>(`plos/program/${programID}`);
  }

  editEvaluationCriteria(
    editEvaluationCriterionRequestBody: EditEvaluationCriterionRequestBodyI,
    programID: string
  ) {
    return this.apiService.patch(
      `evaluation-criteria/${programID}`,
      editEvaluationCriterionRequestBody
    );
  }

  aolReport(programID: string, aolReportRequestQuery: AolReportRequestQueryI) {
    return this.apiService.get<AolProgramI>(
      `programs/aol-report/${programID}`,
      aolReportRequestQuery
    );
  }
}
